import CountryFilter from "./Filters/CountryFilter";
import DegreeFilter from "./Filters/DegreeFilter";
import DisciplineFilter from "./Filters/DisciplineFilter";
import DurationFilter from "./Filters/DurationFilter";
import FeeRangeFilter from "./Filters/FeeRangeFilter";
import InstituteNameFilter from "./Filters/InstituteNameFilter";
import InstituteTypeFilter from "./Filters/InstituteTypeFilter";
import SpecializationFilter from "./Filters/SpecializationFilter";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import IntakeFilter from "./Filters/IntakeFilter";
import { useSelector } from "react-redux";
import { useState } from "react";
import diamond from "../../../static/images/search/diamond-min.png"

const Filters = ({
  setOpenModal,
  countryData,
  searchParams,
  updateSearchParams,
  setSearchParams,
}) => {
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  console.log("isauthenticated", isAuthenticated);
  const [isBlurred, setIsBlurred] = useState(!isAuthenticated);

  const resetFilter = () => {
    let resetAll = {
      fee_range: "",
      discipline: "",
      degreelevel: "",
      specialization: "",
      course_duration: "",
      institute_type: "",
      institute: "",
      degreelevel_type: "",
      ordering: "",
      campus__institute__instituteranking__ranking_type__in: "",
      page: 1,
    };
    window.location.search = new URLSearchParams({ page: 1 });
    setSearchParams(resetAll);
    history.replace({
      pathname: `${window.location.pathname}/`,
      search: {},
    });
  };
  // useEffect(() => {
  //   // getFilterData()
  // }, [searchParams]);
  return (
    <>
      <div className="top">
        <h5> Filters </h5>
        <button className="btn" onClick={resetFilter}>
          Reset All
        </button>
      </div>
      {isBlurred && (
        <div className="filter-top" >
         <img src={diamond} alt=" diamond" />
          <p style={{fontSize:"12px"}}>
            To access Advance Filters you need to <span style={{ textDecoration:"underLine",color:"blue", cursor:"pointer"}} onClick={()=>setOpenModal(true)}>log in</span>
          </p>
        </div>
      )}
      <div
        className={`filter-container`}
        style={{ filter: isBlurred ? "blur(5px)" : "none" }}
      >
        {/* // <div className="sidebar filterSidebar webFilter v2"> */}

        {/* Filters */}
        <DisciplineFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          filterName={"Discipline"}
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
        />

        <DegreeFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          filterName={"Degree Level"}
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
        />

        <CountryFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          data={countryData}
          filterName={"Location"}
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
        />

        <IntakeFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          filterName={"Intake"}
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
        />

        <SpecializationFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          searchParams={searchParams}
          updateSearchParams={updateSearchParams}
        />

        <InstituteTypeFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          searchParams={searchParams}
          updateSearchParams={updateSearchParams}
        />

        <InstituteNameFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          searchParams={searchParams}
          updateSearchParams={updateSearchParams}
        />

        <DurationFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          updateSearchParams={updateSearchParams}
        />

        <FeeRangeFilter
          setOpenModal={setOpenModal}
          isBlurred={isBlurred}
          updateSearchParams={updateSearchParams}
          searchParams={searchParams}
        />
        {/* // </div> */}
      </div>
    </>
  );
};

export default Filters;

import React, { useCallback, useEffect, useRef, useState } from "react";
import TopHeader from "./top_header";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { BASE_URL } from "../../services/apiService/apiService";
import Fuse from "fuse.js";
import siteSuggestion from "../../Dictionary/site-map.json";
import { getSingleRoute } from "../../../common/utils";
import { getQueryString, slugSet } from "../../common/getQueryString";
import defaultDp from "../../../static/images/dummy-user.png"
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Modal, SpeedDial, SpeedDialAction, Typography } from "@mui/material";
import { CloseOutlined, Feedback, HelpOutline } from "@material-ui/icons";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FAQS, FAQ_MODAL_STYLE } from "../../constants/faqs";
import { QuestionMark } from "@mui/icons-material";
import { AUTH_PAGES, ENVIRONMENT } from "../../constants";
import { LogoutLink, ory } from "../../ory/pkg";
import DiscoverIcon from "../../../static/images/discover.png";
import CompareIcon from "../../../static/images/compare.png";
import ApplyIcon from "../../../static/images/apply.png";
import Logo from "../../../static/images/newLogo.svg";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { logoutOryUser } from "../../store/actions/authActions";
import { useNavigate } from "react-router-dom-v5-compat";
import { LiveChatLoaderProvider, Messenger } from 'react-live-chat-loader'
import { useMediaQuery } from "@material-ui/core";
import { debounce } from "../../utils/debounce";
import { isValidImageURL } from "../../utils/validateImage";

function Header(props) {
  let history = useHistory();
  const siteSuggestionOptions = {
    isCaseSensitive: false,
    includeScore: false,
    shouldSort: true,
    includeMatches: false,
    findAllMatches: false,
    minMatchCharLength: 1,
    location: 0,
    threshold: 0.0,
    distance: 100,
    useExtendedSearch: false,
    ignoreLocation: false,
    ignoreFieldNorm: false,
    fieldNormWeight: 1,
    keys: ["name"],
  };

  const fuse = new Fuse(siteSuggestion, siteSuggestionOptions);
  const [_error, set_Error] = useState(false);
  const [isImageValid, setIsImageValid] = useState(null);

  const user = useSelector((state) => state.auth);
  const navigate = useNavigate()
  const onLogout = LogoutLink()

  const location = useLocation()

  const [suggestedSiteMap, setSuggestedSiteMap] = useState([]);
  const [fileSearch, setFileSearch] = useState([]);

  const abortControllerRef = useRef(null);

  async function onSiteMapInputSearchSuggestion(text, controller) {
    const response = await fetch(BASE_URL + `site-suggestion?name=${text}`, {
      signal: controller.signal,
    });
    return await response.json();
  }

  const searchSiteMap = async (text) => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const controller = new AbortController();
    abortControllerRef.current = controller;

    let result = fuse.search(text);
    setFileSearch(result);
    try {
      let results = await onSiteMapInputSearchSuggestion(text, controller);

      results = results.results;
      if (results.length > 0 && result.length > 0) {
        set_Error(false);
      } else {
        set_Error(true);
      }

      if (text) {
        let filteredData = results.filter(function (obj) {
          let name = obj["name"].replace(/[^a-z0-9,. ]/gi, "");
          return name.toUpperCase().includes(text.toUpperCase()) || name.toLowerCase().includes(text.toLowerCase());
        });
        setSuggestedSiteMap(groupByType(filteredData));
      } else {
        setSuggestedSiteMap(groupByType(results));
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        console.log('Fetch aborted');
      } else {
        console.error('Fetch error:', error);
      }
    }
  };
  const groupByType = (result) => {
    return result.reduce(
      (item, { name, id, type, institute_id, campus_id, slug, campus_slug, country_slug, course_slug, degree_level_slug, discipline }) => {
        if (!item[type]) item[type] = [];
        item[type].push({
          slug: slug,
          course_slug: course_slug,
          degree_level_slug: degree_level_slug,
          discipline: discipline,
          campus_slug: campus_slug,
          country_slug: country_slug,
          id: institute_id ? institute_id : id,
          name: name,
          type: type,
          campus_id: campus_id,
        });
        return item;
      },
      {}
    );
  };
  const courseNavigate = (item) => {
    history.push({
      pathname: `/course-detail/${item.slug}`,
    });
  };
  const disciplineNavigate = (item) => {
    history.push({
      pathname: "/discipline-detail",
      search: getQueryString({ discipline: [item.slug] })
    });
  };

  const scholarshipNavigate = (item) => {
    history.push({
      pathname: `/scholarship-detail/${item.slug}`,
    });
  };
  const instituteNavigate = (item) => {
    history.push({
      // pathname: `/university-details/${slugSet(item?.slug)}${slugSet(item?.campus_slug)}`
      pathname: `/university-details/${slugSet(item?.slug)}`
    });
    // window.location.reload();
  };
  const countryNavigate = (item) => {
    history.push({
      pathname: `/country/${item.slug}/`,
    });
    // window.location.reload();
  };
  const instituteGroupNavigate = (item) => {
    history.push({
      pathname: `/group-universities/${item.slug}`,
    });
  };
  const favoriteNavigate = (event) => {
    history.push({
      pathname: "/shortlist",
    });
  };
  const visaListClick = (event) => {
    history.push({
      pathname: "/all-visas",
    });
  };
  const trackApplication = (event) => {
    history.push({
      pathname: "/track-application",
    });
  };

  const getRoute = (page, id) => {
    getSingleRoute(page).then(d => { history.push(d + `${id?.id ? '/' + id?.id : ''}`) })
  }
  const dispatch = useDispatch();

  useEffect(() => {
    (function (d, s, id) { var js; if (d.getElementById(id)) { return; } js = d.createElement(s); js.id = id; js.src = "https://embedsocial.com/cdn/ef.js"; d.getElementsByTagName("head")[0].appendChild(js); }(document, "script", "EmbedSocialFormsScript"));
    // logout user if session is expired
    ory.toSession().catch(err => {
      if (err.response.status === 401) {
        dispatch(logoutOryUser())
      }
    })
  }, [])

  const isMobile = useMediaQuery("(max-width: 600px)");
  const [openFAQs, setOpenFAQs] = useState(false);
  const handleOpenFAQs = () => setOpenFAQs(true);
  const handleCloseFAQs = () => setOpenFAQs(false);

  // Debounce the searchSiteMap function
  const debouncedSearchSiteMap = useCallback(debounce(searchSiteMap, 400), []);


  useEffect(() => {
    const validateImageURL = async () => {
      if (user.isAuthenticated) {
        const valid = await isValidImageURL(user.user.picture);
        setIsImageValid(valid);
      } else {
        setIsImageValid(null); // Reset validation if the URL is empty
      }
    };

    validateImageURL();
  }, [user]);

  return !AUTH_PAGES.includes(location.pathname) ? (
    <div>
      {user.isAuthenticated ? (
        <div className="header loginHeader">
          <TopHeader />
          <div className="container-fluid">
            <div className="headBottom">
              <Link className="logo" to={"/"}>
                <img src={Logo} height="" width="" alt="header-logo" />{" "}
              </Link>
              <div className="middle">
                <nav className="main-menu-nav ">
                  <div className="body-overlay" />
                  <div className="menu-toggler" />
                  <div className="main-menu-div">
                    {/* <ul>
											<li>
												<a href="#">
													<img
														src={
															require("../../../static/images/discover.png")
																.default
														}
														alt="banner"
													/>
													Discover
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src={
															require("../../../static/images/compare.png")
																.default
														}
														alt="banner"
													/>
													Compare
												</a>
											</li>
											<li>
												<a href="#">
													<img
														src={
															require("../../../static/images/apply.png")
																.default
														}
														alt="banner"
													/>
													Apply
												</a>
											</li>
										</ul> */}
                    <ul>
                      <li>
                        <button
                          onClick={() => getRoute('discover_course')}
                          title="Instantly explore thousands of programs at all academic levels within your budget."
                        >
                          <img
                            src={DiscoverIcon}
                            alt="discover"
                          />
                          Discover
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => getRoute('course_compare')}
                          title="Shortlist and compare your university programs to decide which one is best for you."
                        >
                          <img
                            src={CompareIcon}
                            alt="compare"
                          />
                          Compare
                        </button>
                      </li>
                      <li>
                        <button
                          onClick={() => getRoute('apply')}
                          title="Get firsthand assistance and advice by our experts to lodge your application seamlessly."
                        >
                          <img
                            src={ApplyIcon}
                            alt="apply"
                          />
                          Apply
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="right loginHeadRight">
                <div className="dropdown topSearch">
                  <button
                    className="button"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        require("../../../static/images/header-search.svg")
                          .default
                      }
                      alt="header-search"
                    />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <img
                      src={
                        require("../../../static/images/top-search-arrow.svg")
                          .default
                      }
                      alt="header-search"
                      className="topShape"
                    />
                    <div className="dropInner">
                      <div className="searchWrap">
                        <input
                          type="text"
                          placeholder="search"
                          className="form-control"
                          onChange={(e) => debouncedSearchSiteMap(e.target.value)}
                        />
                        <button>
                          <img
                            src={
                              require("../../../static/images/dropdown-search.svg")
                                .default
                            }
                            alt="dropdown-search"
                          />
                        </button>
                      </div>
                      <div className="sitemapScroll">
                        {Object.keys(suggestedSiteMap).length ? (
                          <>
                            {suggestedSiteMap?.InstituteGroup && (
                              <div className="searchSecBox">
                                <p className="tag">Institute Group</p>
                                <ul>
                                  {suggestedSiteMap?.InstituteGroup?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            instituteGroupNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Country && (
                              <div className="searchSecBox">
                                <p className="tag">Country</p>
                                <ul>
                                  {suggestedSiteMap?.Country?.map((subItem) => (
                                    <li>
                                      <a
                                        href
                                        onClick={() => countryNavigate(subItem)}
                                      >
                                        {subItem.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Institute && (
                              <div className="searchSecBox">
                                <p className="tag">Institute</p>
                                <ul>
                                  {suggestedSiteMap?.Institute?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            instituteNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Course && (
                              <div className="searchSecBox">
                                <p className="tag">Course</p>
                                <ul>
                                  {suggestedSiteMap?.Course?.map((subItem) => (
                                    <li>
                                      <a
                                        href
                                        onClick={() => courseNavigate(subItem)}
                                      >
                                        {subItem.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Discipline && (
                              <div className="searchSecBox">
                                <p className="tag">Discipline</p>
                                <ul>
                                  {suggestedSiteMap?.Discipline?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            disciplineNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Blog && (
                              <div className="searchSecBox">
                                <p className="tag">Blog</p>
                                <ul>
                                  {suggestedSiteMap?.Blog?.map((subItem) => (
                                    <li>
                                      <a href={`/blog-detail/${subItem.slug}`}>
                                        {subItem.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Scholarship && (
                              <div className="searchSecBox">
                                <p className="tag">Scholarship</p>
                                <ul>
                                  {suggestedSiteMap?.Scholarship?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            scholarshipNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </>
                        ) : null}
                        {fileSearch.length > 0 ? (
                          <div className="searchSecBox">
                            <p className="tag">Other Pages</p>
                            <ul>
                              {fileSearch.map((subItem) => (
                                <li>
                                  <button to={subItem.item.links}>
                                    {subItem.item.name}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : _error ? (
                          <p style={{ textAlign: "center" }}>No match found</p>
                        ) : null}
                      </div>

                      {/* <p className="tag">Courses</p>
											<ul>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
											</ul>
											<a href="#" className="showBtn">
												Show all
											</a>
										</div> */}
                      {/* <div className="searchSecBox">
											<p className="tag">Courses</p>
											<ul>
												<li>
													<a href="#">
														<b>Massey</b> University Foundation Education
														Programme
													</a>
												</li>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
												<li>
													<a href="#">
														20170 <b>Mass</b> Communications & Acting
													</a>
												</li>
											</ul>
											<a href="#" className="showBtn">
												Show all
											</a>
										</div> */}
                    </div>
                  </div>
                </div>

                <button className="headBtn" onClick={(e) => visaListClick(e)}>
                  <img
                    title="Visa List"
                    src={
                      require("../../../static/images/head-icon1.svg").default
                    }
                    alt="head-icon1"
                  />
                </button>
                <button
                  className="headBtn"
                  onClick={(e) => favoriteNavigate(e)}
                >
                  <img
                    title="Shortlisted Courses"
                    src={
                      require("../../../static/images/head-icon2.svg").default
                    }
                    alt="head-icon2"
                  />
                </button>
                <button
                  className="headBtn"
                  onClick={(e) => trackApplication(e)}
                >
                  <img
                    title="Track Applications"
                    src={
                      require("../../../static/images/head-icon3.svg").default
                    }
                    alt="head-icon3"
                  />
                </button>
                <div className="dropdown">
                  <button
                    className="btn dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={isImageValid ? user.user.picture : defaultDp}
                      alt="dp"
                    />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <h6 className="dropdown-item">
                      <strong>{user.user.userDetails.first + " " + user.user.userDetails.last}</strong>
                    </h6>
                    <a className="dropdown-item" href="/edit-profile">
                      <img
                        src={
                          require("../../../static/images/head-drop1.svg")
                            .default
                        }
                        alt="head-drop1"
                      />
                      Profile
                    </a>
                    <a
                      className="dropdown-item"
                      href
                      onClick={onLogout}
                    >
                      <img
                        src={
                          require("../../../static/images/head-drop2.svg")
                            .default
                        }
                        alt="head-drop2"
                      />
                      Sign out
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="header">
          <TopHeader />
          <div className="container-fluid">
            <div className="headBottom">
              <Link className="logo" to={"/"}>
                <img src={Logo} height="" width="" alt="header-logo" />{" "}
              </Link>
              <div className="middle">
                <nav className="main-menu-nav ">
                  <div className="body-overlay" />
                  <div className="menu-toggler" />
                  <div className="main-menu-div">
                    {/* <div className="menu-header">
									<a href="#" className="home-icon fa fa-home"></a>
									<span className="close-icon fa fa-times"></span>
								</div> */}
                    <ul>
                      <li>
                        {/* <Popup
												className="headPopup"
												position="top center"
												content="Instantly explore thousands of programs at all academic levels within your budget."
												trigger={ */}
                        <button
                          onClick={() => getRoute('discover_course')}
                          title="Instantly explore thousands of programs at all academic levels within your budget."
                        >
                          <img
                            src={DiscoverIcon}
                            alt="discover"
                          />
                          Discover
                        </button>
                        {/* }
											></Popup> */}
                      </li>
                      <li>
                        {/* <Popup
												className="headPopup"
												position="top center"
												content="Shortlist and compare your university programs to decide which one is best for you."
												trigger={ */}
                        <button
                          onClick={() => getRoute('course_compare')}
                          title="Shortlist and compare your university programs to decide which one is best for you."
                        >
                          <img
                            src={CompareIcon}
                            alt="compare"
                          />
                          Compare
                        </button>
                        {/* }
											></Popup> */}
                      </li>
                      <li>

                        <button
                          onClick={() => getRoute('apply')}
                          title="Get firsthand assistance and advice by our experts to lodge your application seamlessly."
                        >
                          <img
                            src={ApplyIcon}
                            alt="apply"
                          />
                          Apply
                        </button>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="right">
                <div className="dropdown topSearch">
                  <button
                    className="button"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <img
                      src={
                        require("../../../static/images/header-search.svg")
                          .default
                      }
                      alt="header-search"
                    />
                  </button>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                  >
                    <img
                      src={
                        require("../../../static/images/top-search-arrow.svg")
                          .default
                      }
                      alt="top-search-arrow"
                      className="topShape"
                    />
                    <div className="dropInner">
                      <div className="searchWrap">
                        <input
                          type="text"
                          placeholder="search"
                          className="form-control"
                          onChange={(e) => debouncedSearchSiteMap(e.target.value)}
                        ></input>
                        <button>
                          <img
                            src={
                              require("../../../static/images/dropdown-search.svg")
                                .default
                            }
                            alt="dropdown-search"
                          />
                        </button>
                      </div>
                      <div className="sitemapScroll">
                        {Object.keys(suggestedSiteMap).length ? (
                          <>
                            {suggestedSiteMap?.InstituteGroup && (
                              <div className="searchSecBox">
                                <p className="tag">Institute Group</p>
                                <ul>
                                  {suggestedSiteMap?.InstituteGroup?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            instituteGroupNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Country && (
                              <div className="searchSecBox">
                                <p className="tag">Country</p>
                                <ul>
                                  {suggestedSiteMap?.Country?.map((subItem) => (
                                    <li>
                                      <a
                                        href
                                        onClick={() => countryNavigate(subItem)}
                                      >
                                        {subItem.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Institute && (
                              <div className="searchSecBox">
                                <p className="tag">Institute</p>
                                <ul>
                                  {suggestedSiteMap?.Institute?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            instituteNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Course && (
                              <div className="searchSecBox">
                                <p className="tag">Course</p>
                                <ul>
                                  {suggestedSiteMap?.Course?.map((subItem) => (
                                    <li>
                                      <a
                                        href
                                        onClick={() => courseNavigate(subItem)}
                                      >
                                        {subItem.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Discipline && (
                              <div className="searchSecBox">
                                <p className="tag">Discipline</p>
                                <ul>
                                  {suggestedSiteMap?.Discipline?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            disciplineNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Blog && (
                              <div className="searchSecBox">
                                <p className="tag">Blog</p>
                                <ul>
                                  {suggestedSiteMap?.Blog?.map((subItem) => (
                                    <li>
                                      <a href={`/blog-detail/${subItem.slug}`}>
                                        {subItem.name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              </div>
                            )}
                            {suggestedSiteMap?.Scholarship && (
                              <div className="searchSecBox">
                                <p className="tag">Scholarship</p>
                                <ul>
                                  {suggestedSiteMap?.Scholarship?.map(
                                    (subItem) => (
                                      <li>
                                        <a
                                          href
                                          onClick={() =>
                                            scholarshipNavigate(subItem)
                                          }
                                        >
                                          {subItem.name}
                                        </a>
                                      </li>
                                    )
                                  )}
                                </ul>
                              </div>
                            )}
                          </>
                        ) : null}
                        {fileSearch.length > 0 ? (
                          <div className="searchSecBox">
                            <p className="tag">Other Pages</p>
                            <ul>
                              {fileSearch.map((subItem) => (
                                <li>
                                  <button to={subItem.item.links}>
                                    {subItem.item.name}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ) : _error ? (
                          <p style={{ textAlign: "center" }}>No match found</p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <button
                  className="accBtn"
                  onClick={onClickLogin}
                >
                  <img
                    src={
                      require("../../../static/images/account-icon.svg").default
                    }
                    alt="account-icon"
                  />
                  <span>Login</span>
                </button> */}

                <Button variant="contained" className={`tcf-older-button-small older-color ${!isMobile ? "outlined" : ""}`}
                  onClick={() => navigate("/login")}
                >
                  Login
                </Button>
                <Button variant="contained" className={`tcf-older-button-small older-color ${isMobile ? "outlined" : ""}`}
                  onClick={() => navigate("/registration")}
                >
                  Create Account
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {(process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.beta || process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.production || process.env.REACT_APP_ENVIRONMENT === ENVIRONMENT.staging) &&
        <>
          <SpeedDial
            ariaLabel="Need help?"
            sx={{ position: 'fixed', bottom: 16, right: 16 }}
            icon={<QuestionMark style={{ color: "#fff" }} />}
            FabProps={{
              size: "medium", style: {
                backgroundColor: "#573e69",
                width: "56px",
                height: "56px",
                borderRadius: "50%",
              }
            }}
          >
            {/* <SpeedDialAction tooltipTitle="Live Chat" name="Live Chat"
              icon={
                <LiveChatLoaderProvider
                  provider="messenger"
                  providerKey="107225098219272"
                  appID="867455750856341"
                  locale="en_US"
                >
                  <Messenger color="#47305c" height="20px" />
                </LiveChatLoaderProvider>
              }
            /> */}

            <SpeedDialAction
              tooltipTitle="Feedback"
              sx={{ bottom: 10 }}
              name="Feedback"
              className="homepage-speeddial"
              FabProps={{
                style: {
                  backgroundColor: "#47305c",
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                },
              }}
              icon={<Feedback id="embeddSocialTrigger" style={{ color: "#fff" }} />}
            />
            <SpeedDialAction
              tooltipTitle="Help"
              sx={{ bottom: 15 }}
              name="Help"
              className="homepage-speeddial"
              FabProps={{
                style: {
                  backgroundColor: "#573e69",
                  width: "48px",
                  height: "48px",
                  borderRadius: "50%",
                },
              }}
              icon={<HelpOutline onClick={handleOpenFAQs} style={{ color: "#fff" }} />}
            />
          </SpeedDial>
          <Modal
            open={openFAQs}
            onClose={handleCloseFAQs}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableScrollLock
            disableEnforceFocus
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(255,255,255,0)' } } }}
          >
            <Box sx={FAQ_MODAL_STYLE}>
              <div className="faqs-header">
                <h3 className="text-center p-2" >Help</h3>
                <CloseOutlined onClick={handleCloseFAQs} style={{ position: 'absolute', top: '10px', right: '10px', cursor: 'pointer' }} />
              </div>
              <div style={{ height: '70vh', overflow: 'scroll' }}>
                {FAQS.map(faq => (
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1-content"
                      id="panel1-header"
                    >
                      <Typography><b>{faq.question}</b></Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </div>
            </Box>
          </Modal>
        </>
      }
    </div>
  ) : null;
}

export default Header;